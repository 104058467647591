import * as React from "react";
import AusApplyPage from "../containers/application-form-aus";
import DefaultLayout from "../layouts/default";
export default () => {
    return (
        <>
            <DefaultLayout title="Apply Now">
                <AusApplyPage />
            </DefaultLayout>

        </>
    );
};